import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { setIsGettingExtensionMediaPermission, setMediaRecordingChangeDirection, setShowDisableMediaRecordingModal, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { eventTypes } from "types/eventTracking";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import {
  setToggleAutoNotes,
  setIsAutoNotesSettingsOpen,
  setShowPermissionGuideInAutoNotesSettingsView,
  setIsGettingAutoNotesMediaPermission,
  setStopAutoNotes,
  setIsAutoNotesTemporaryVisible,
  setPreviousTranscriptBufferBlocks,
  setInsertedTranscriptBufferBlocks,
  resetActiveTranscriptBufferBlock,
} from "redux/modules/autoNotesSlice";
import { AutoNotesButtonStatus } from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesButtonIcon";
import { MediaRecordingChangeDirection } from "components/popups/DisableMediaRecordingModal";
import { insertAutoNotesLoaderBlock } from "components/smartautonotes/utils";
import useAutoNotesTranscriptStore from "store/useAutoNotesTranscript";

export const useAutoNotesButton = () => {
  const { isExtensionMediaPermitted, isRecordActive, isVideoPlaying } = useAppSelector((state) => state.vdocs);
  const dispatch = useAppDispatch();
  const [buttonStatus, setButtonStatus] = useState<AutoNotesButtonStatus>("default");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);
  const [isMouseOnButton, setIsMouseOnButton] = useState<boolean>(false);
  const { isAutoNotesSettingsOpen, autoNotesVideoLang, autoNotesResultLang, isAutoNotesActive, isAutoNotesToggledOn, activeTranscriptBufferBlock } = useAppSelector((state) => state.autoNotes);
  const { isSTTToggledOn } = useAppSelector((state) => state.sttReducer);
  const { localSTTData, setLocalSTTData } = useAutoNotesTranscriptStore();

  useEffect(() => {
    let timeout;
    if (isButtonHovered) {
      dispatch(setIsAutoNotesTemporaryVisible(true));
    } else {
      timeout = setTimeout(() => {
        dispatch(setIsAutoNotesTemporaryVisible(false));
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isButtonHovered, dispatch]);

  useEffect(() => {
    const updateAutoNotesButtonUI = () => {
      if (isAutoNotesActive && isVideoPlaying) {
        //NOTE: iframe videos won't give us this information. (We won't know if the vid is playing then.)
        setButtonStatus("generating");
      } else if (isAutoNotesToggledOn) {
        //should also consider auto notes
        setButtonStatus("waiting");
      } else {
        setButtonStatus("default");
      }
    };
    updateAutoNotesButtonUI();
  }, [isAutoNotesToggledOn, isAutoNotesActive, isVideoPlaying]);

  const requestExtensionPermission = () => {
    trackEvent({
      eventType: "Check AUTO-NOTES PERMISSION in video note page",
      eventProperties: {
        is_permission_needed: !isExtensionMediaPermitted,
      },
    });

    dispatch(setShowExtensionMediaPermissionModal(true));
    dispatch(setIsGettingExtensionMediaPermission(true));
    dispatch(setIsGettingAutoNotesMediaPermission(true));
    dispatch(setShowPermissionGuideInAutoNotesSettingsView(true));
    //make this async to avoid blocking the main thread.
    Promise.resolve().then(() => {
      localStorage.setItem("isAutoNotesPermissionRequested", "true");
    });
  };

  const onMouseEnterButton = () => {
    setIsButtonHovered(true);
    setIsMouseOnButton(true);
  };

  const onMouseLeaveButton = () => {
    setIsMouseOnButton(false);
    setIsButtonHovered(false);
  };

  const manageClipRecordingEffects = (direction: MediaRecordingChangeDirection) => {
    // when video is being recorded, stop STT and show modal
    dispatch(setToggleAutoNotes(false));
    dispatch(setMediaRecordingChangeDirection(direction));
    dispatch(setShowDisableMediaRecordingModal(true));
  };

  const onToggleAutoNotesOn = () => {
    if (isSTTToggledOn) {
      manageClipRecordingEffects("SLT_TO_AUTO_NOTES");
      return;
    } else if (isRecordActive) {
      manageClipRecordingEffects("SNIPPET_TO_AUTO_NOTES");
      return;
    }
    dispatch(setToggleAutoNotes(true));
    if (!(autoNotesVideoLang && autoNotesResultLang) || !isExtensionMediaPermitted) {
      dispatch(setIsAutoNotesSettingsOpen(true));
    } else {
      dispatch(setIsAutoNotesSettingsOpen(false));
    }

    if (!isExtensionMediaPermitted) {
      requestExtensionPermission();
    }
  };

  const onToggleAutoNotesOff = () => {
    dispatch(setStopAutoNotes());

    //handles cleaning up and processing any buffered transcript data.
    if (activeTranscriptBufferBlock.finalizedText.trim().length || localSTTData?.text.trim().length) {
      const finalText = `${activeTranscriptBufferBlock.finalizedText.trim() || ""} ${localSTTData?.text.trim() || ""}`;
      if (!finalText.trim().length) return;
      dispatch(setPreviousTranscriptBufferBlocks(activeTranscriptBufferBlock));
      insertAutoNotesLoaderBlock({ finalizedText: finalText, blockId: activeTranscriptBufferBlock.blockId });
      dispatch(setInsertedTranscriptBufferBlocks({ blockId: activeTranscriptBufferBlock.blockId }));
      setLocalSTTData(null);
      dispatch(resetActiveTranscriptBufferBlock());
    }
  };

  const onToggleAutoNotes = async () => {
    if (isAutoNotesToggledOn) {
      onToggleAutoNotesOff();
    } else {
      if (!confirmPrivilege(SlidFeatures.autoNotes)) return showInsufficientPrivilegeModal();
      onToggleAutoNotesOn();
    }
  };

  const onClickAutoNotesMoreButton = (e) => {
    trackEvent({
      eventType: eventTypes.click.AUTO_NOTES_SETTING_IN_VIDEO_NOTE_PAGE,
    });

    e.preventDefault();
    e.stopPropagation();
    if (isAutoNotesSettingsOpen) dispatch(setIsAutoNotesSettingsOpen(false));
    else dispatch(setIsAutoNotesSettingsOpen(true));
  };

  return {
    onToggleAutoNotes,
    buttonStatus,
    onMouseEnterButton,
    onMouseLeaveButton,
    isButtonHovered,
    isMouseOnButton,
    onClickAutoNotesMoreButton,
  };
};

import { Typography13 } from "@slid/slid-ips";
import { AutoNotesSideBarIcon, AutoNotesStopIcon } from "components/icons/autoNotesIcons";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import { useAutoNotesButton } from "hooks/autoNotes/useAutoNotesButton";

const AutoNotesSideBarButton = () => {
  const { t } = useTranslation("AutoNotes");
  const { isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { onToggleAutoNotes } = useAutoNotesButton();

  return (
    <ButtonWrapper>
      {!isAutoNotesToggledOn ? (
        <StartAutoNotesButton
          onClick={() => {
            trackEvent({
              eventType: eventTypes.click.AUTO_NOTES_IN_VIDEO_NOTE_PAGE,
              eventProperties: {
                location: "auto-notes side bar",
              },
            });
            if (!confirmPrivilege(SlidFeatures.autoNotes)) return showInsufficientPrivilegeModal();
            onToggleAutoNotes();
          }}
        >
          <AutoNotesSideBarIcon width={22} height={22} color="white" />
          <Typography13 text={t("StartAutoNotes")} color={`--white`} weight={700} />
        </StartAutoNotesButton>
      ) : (
        <EndAutoNotesButton
          onClick={() => {
            onToggleAutoNotes();
            trackEvent({
              eventType: eventTypes.click.STOP_AUTO_NOTES_IN_VIDEO_NOTE_PAGE,
              eventProperties: {
                location: "auto-notes side bar",
              },
            });
          }}
        >
          <AutoNotesStopIcon width={22} height={22} />
          <Typography13 text={t("StopAutoNotes")} color={`--white`} weight={700} />
        </EndAutoNotesButton>
      )}
    </ButtonWrapper>
  );
};

export default AutoNotesSideBarButton;

const ButtonWrapper = styled.div`
  * {
    cursor: pointer;
  }
  width: 100%;
`;

const StartAutoNotesButton = styled.div`
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--blue9);
  padding: 8px;

  &:hover {
    background: var(--blue12);
  }

  &:active {
    background: var(--blue11);
  }
`;

const EndAutoNotesButton = styled.div`
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--gray17);
  height: 38px;
  padding: 6px;

  &:hover {
    background: var(--gray15);
  }

  &:active {
    background: var(--gray17);
  }
`;

import EditorJS from "@editorjs/editorjs";
import useEditorStore from "store/useEditorStore";
import store from "redux/store";
import { IAutoNotesLoaderInput } from "./types";
import { AutoNotesLoaderBlockData } from "components/editor/blocks/autoNotes/types";
import { getCurrentDocumentKey } from "utils/editor/util";
import { exportForAutoNotesLLMS } from "utils/exportNote";

export const insertAutoNotesLoaderBlock = async (newblock: IAutoNotesLoaderInput) => {
  /**
   * This function is responsible for inserting a new auto notes loader block into the editor. The loader block is the responsible for generating auto notes. It will make the api request and handle inserting the result into the editor.
   *
   * @param {IAutoNotesLoaderInput} newblock - The new block to be inserted, containing the finalized text and other metadata.
   *
   * TODO: If you need to mark this block id as inserted in redux, you can do it here...
   */
  if (!newblock) return;
  console.log("New block to insert \n", newblock.finalizedText);
  const { updateNoteContent } = useEditorStore.getState();
  updateNoteContent(true); //done to hide placeholder on editor
  const insertEditorBlock = async (editorMarkdownNotes: string) => {
    const editorInstance = store.getState().vdocs.editorInstance as EditorJS;
    if (!editorInstance) return;
    const { blocks } = editorInstance;
    const { insert: insertBlock } = blocks;
    const newTranscriptBlock = {
      finalizedText: newblock.finalizedText,
    };

    const { previousTranscriptBufferBlocks, autoNotesResultLang } = store.getState().autoNotes;

    // NOTE: we either path these previous transcript blocks this way to show the LLM its previous work or we just save the editor contents and pass them for it as reference.

    // TODO: we might not have to pass this data separately to the backend later if we have the script added to the editor. OR we can save this data as state on the backend?? And have some kind of agent with state.

    const previousTranscriptBlocks = previousTranscriptBufferBlocks.map((block) => ({
      finalizedText: block.finalizedText,
    }));
    const blockData: AutoNotesLoaderBlockData = {
      newTranscriptBlock: newTranscriptBlock,
      previousTranscriptBlocks: previousTranscriptBlocks,
      language: autoNotesResultLang,
      previousNotes: { text: editorMarkdownNotes },
    };
    let insertIndex = editorInstance.blocks.getBlocksCount();
    if (insertIndex > 1) {
      const previousEditorBlock = editorInstance.blocks.getBlockByIndex(insertIndex - 1);
      if (previousEditorBlock && previousEditorBlock.isEmpty) {
        insertIndex--;
      }
    }
    insertBlock(
      "autoNotesLoader", // block type
      {
        ...blockData,
      }, // block tool data
      {}, // config - for when you want to pass some functions to the block.
      insertIndex, // insert index number
      false, // need to focus
      false, // replace?
      newblock.blockId // block id to insert block as.
    );
  };
  const currentDocumentKey = await getCurrentDocumentKey();
  if (!currentDocumentKey) return false;
  const markdownContent = await exportForAutoNotesLLMS();
  try {
    await insertEditorBlock(markdownContent);
  } catch (error) {
    console.error("Failed to insert editor block:", error);
    //TODO: track with sentry.
    return false;
  }

  return true;
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getTranscriptLanguageCode } from "utils/stt";
import { sendRestartSTTRequestToParentWindow, sendStartSTTRequestToParentWindow, sendStopSTTRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { AutoNotesTranscriptBufferBlock } from "components/smartautonotes/types";
import { v4 as uuidv4 } from "uuid";

const name = "autoNotes";

interface State {
  activeTranscriptBufferBlock: AutoNotesTranscriptBufferBlock;
  previousTranscriptBufferBlocks: AutoNotesTranscriptBufferBlock[];
  insertedTranscriptBufferBlocks: { blockId: string }[];
  autoNotesVideoLang: string;
  autoNotesResultLang: string;
  isAutoNotesToggledOn: boolean;
  isAutoNotesSettingsOpen: boolean;
  showPermissionGuideInAutoNotesSettingsView: boolean;
  isGettingAutoNotesMediaPermission: boolean;
  isAutoNotesActive: boolean;
  autoNotesSTTError: string | null;
  isAutoNotesVisible: boolean;
  isAutoNotesTemporaryVisible: boolean;
  isAutoNotesSupported: boolean;
  isSocketConnected: boolean;
}

const initialState: State = {
  activeTranscriptBufferBlock: {
    finalizedText: "",
    wordCount: 0,
    blockId: uuidv4(),
  },
  previousTranscriptBufferBlocks: [],
  insertedTranscriptBufferBlocks: [],
  autoNotesVideoLang: "",
  autoNotesResultLang: "",
  isAutoNotesToggledOn: false,
  isAutoNotesSettingsOpen: false,
  isAutoNotesActive: false,
  showPermissionGuideInAutoNotesSettingsView: false,
  isGettingAutoNotesMediaPermission: false,
  autoNotesSTTError: null,
  isAutoNotesVisible: true,
  isAutoNotesTemporaryVisible: false,
  isAutoNotesSupported: false,
  isSocketConnected: false,
};

export const autoNotesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setToggleAutoNotes: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesToggledOn = action.payload;
    },
    setAutoNotesVideoLang: (state, action: PayloadAction<string>) => {
      state.autoNotesVideoLang = action.payload;
    },
    setAutoNotesResultLang: (state, action: PayloadAction<string>) => {
      state.autoNotesResultLang = action.payload;
    },
    setIsAutoNotesSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesSettingsOpen = action.payload;
    },
    setActiveTranscriptBufferBlock: (state, action: PayloadAction<{ text: string }>) => {
      state.activeTranscriptBufferBlock = {
        ...state.activeTranscriptBufferBlock,
        finalizedText: `${state.activeTranscriptBufferBlock.finalizedText} ${action.payload.text}`,
        wordCount: state.activeTranscriptBufferBlock.wordCount + (action.payload.text.split(" ").length || 0),
      };
    },
    resetActiveTranscriptBufferBlock: (state) => {
      state.activeTranscriptBufferBlock = {
        finalizedText: "",
        wordCount: 0,
        blockId: uuidv4(),
      };
    },
    //NOTE: change this PreviousTranscriptBufferBlocks should trigger the insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks to run and generate auto notes results.
    setPreviousTranscriptBufferBlocks: (state, action: PayloadAction<AutoNotesTranscriptBufferBlock>) => {
      state.previousTranscriptBufferBlocks = [...state.previousTranscriptBufferBlocks, action.payload];
    },
    setInsertedTranscriptBufferBlocks: (state, action: PayloadAction<{ blockId: string }>) => {
      state.insertedTranscriptBufferBlocks = [...state.insertedTranscriptBufferBlocks, action.payload];
    },
    setShowPermissionGuideInAutoNotesSettingsView: (state, action: PayloadAction<boolean>) => {
      state.showPermissionGuideInAutoNotesSettingsView = action.payload;
    },
    setIsGettingAutoNotesMediaPermission: (state, action: PayloadAction<boolean>) => {
      state.isGettingAutoNotesMediaPermission = action.payload;
    },
    setStartAutoNotes: (state) => {
      const language = state.autoNotesVideoLang;
      const transcriptLanguage = getTranscriptLanguageCode(language);
      sendStartSTTRequestToParentWindow(transcriptLanguage, true);
      state.isAutoNotesActive = true;
    },
    setAutoNotesSTTError: (state, action: PayloadAction<string>) => {
      state.autoNotesSTTError = action.payload;
    },
    setRestartAutoNotesSTT: (state) => {
      sendRestartSTTRequestToParentWindow();
    },
    setEndAutoNotesSTT: (state) => {
      // const language = state.autoNotesVideoLang;
      // const transcriptLanguage = getTranscriptLanguageCode(language);
      // sendStartSTTRequestToParentWindow(transcriptLanguage, true);
      // state.isAutoNotesSTTActive = true;
      sendStopSTTRequestToParentWindow();
    },
    setStopAutoNotes: (state) => {
      state.isAutoNotesToggledOn = false;
      state.isAutoNotesActive = false;
      sendStopSTTRequestToParentWindow();
    },
    setIsAutoNotesVisible: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesVisible = action.payload;
    },
    setIsAutoNotesTemporaryVisible: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesTemporaryVisible = action.payload;
    },
    setIsAutoNotesSupported: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesSupported = action.payload;
    },
    setIsSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
  },
});

export const {
  setToggleAutoNotes,
  setAutoNotesVideoLang,
  setAutoNotesResultLang,
  setIsAutoNotesSettingsOpen,
  setShowPermissionGuideInAutoNotesSettingsView,
  setIsGettingAutoNotesMediaPermission,
  setStartAutoNotes,
  setRestartAutoNotesSTT,
  setAutoNotesSTTError,
  setEndAutoNotesSTT,
  setStopAutoNotes,
  setIsAutoNotesVisible,
  setIsAutoNotesTemporaryVisible,
  setIsAutoNotesSupported,
  setIsSocketConnected,
  setActiveTranscriptBufferBlock,
  resetActiveTranscriptBufferBlock,
  setPreviousTranscriptBufferBlocks,
  setInsertedTranscriptBufferBlocks,
} = autoNotesSlice.actions;

export default autoNotesSlice.reducer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography12, Typography16 } from "@slid/slid-ips";
import { setAutoNotesSTTDataResponseListener, setAutoNotesSTTErrorListener, setSTTSocketConnectionStatusListener } from "utils/extensionInterface/setListenerFromExtension";
import { sendRestartSTTRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { useAppDispatch, useAppSelector } from "hooks";

import * as Sentry from "@sentry/browser";
import {
  setEndAutoNotesSTT,
  setStartAutoNotes,
  setIsSocketConnected,
  setActiveTranscriptBufferBlock,
  resetActiveTranscriptBufferBlock,
  setPreviousTranscriptBufferBlocks,
  setInsertedTranscriptBufferBlocks,
} from "redux/modules/autoNotesSlice";
import { useTranslation } from "react-i18next";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import env from "config/env";
import { insertAutoNotesLoaderBlock } from "../utils";
import useAutoNotesTranscriptStore from "store/useAutoNotesTranscript";

const COMPACT_FORCE_IS_FINAL_WORD_COUNT = 70;
const COMPACT_AUTO_NOTES_STT_BUFFER_WORD_COUNT = 60;

const FORCE_IS_FINAL_WORD_COUNT = 140;
const AUTO_NOTES_STT_BUFFER_WORD_COUNT = 120;

const COMPACT_LANGUAGES = ["한국어", "中文", "日本語"];

const AutoNotesMainUI = ({ width }: { width: number }) => {
  // const [localSTTData, setLocalSTTData] = useState<LocalSTTData | null>(null);
  const { localSTTData, setLocalSTTData } = useAutoNotesTranscriptStore();
  // TODO: make sure to reset state when the document is changed since the data will have to change too.

  // this one is used to prevent insertion of the same transcript buffer block twice.
  // const [insertedTranscriptBufferBlocks, setInsertedTranscriptBufferBlocks] = useState<AutoNotesTranscriptBufferBlock[]>([]); // these are already inserted transcript buffer blocks.
  const dispatch = useAppDispatch();
  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo } = useAppSelector((state) => state.vdocs);
  const {
    isAutoNotesToggledOn,
    autoNotesVideoLang,
    autoNotesResultLang,
    isAutoNotesVisible,
    isAutoNotesTemporaryVisible,
    isAutoNotesActive,
    isSocketConnected,
    activeTranscriptBufferBlock,
    previousTranscriptBufferBlocks,
    insertedTranscriptBufferBlocks,
  } = useAppSelector((state) => state.autoNotes);
  const { t } = useTranslation("AutoNotes");
  const [forceRestartWordCount, setForceRestartWordCount] = useState(FORCE_IS_FINAL_WORD_COUNT);
  const [autoNotesSTTBufferWordCount, setAutoNotesSTTBufferWordCount] = useState(AUTO_NOTES_STT_BUFFER_WORD_COUNT);

  useEffect(() => {
    if (COMPACT_LANGUAGES.includes(autoNotesVideoLang)) {
      setForceRestartWordCount(COMPACT_FORCE_IS_FINAL_WORD_COUNT);
      setAutoNotesSTTBufferWordCount(COMPACT_AUTO_NOTES_STT_BUFFER_WORD_COUNT);
    } else {
      setForceRestartWordCount(FORCE_IS_FINAL_WORD_COUNT);
      setAutoNotesSTTBufferWordCount(AUTO_NOTES_STT_BUFFER_WORD_COUNT);
    }
  }, [autoNotesVideoLang]);

  useEffect(() => {
    setSTTSocketConnectionStatusListener({
      responseHandler: ({ isSTTSocketConnected }: { isSTTSocketConnected: boolean }) => {
        dispatch(setIsSocketConnected(isSTTSocketConnected)); //socket must be connected for auto notes to work.
      },
    });
    setAutoNotesSTTDataResponseListener({
      responseHandler: (receivedData: any) => {
        setLocalSTTData(receivedData);
      },
    });

    setAutoNotesSTTErrorListener({
      responseHandler: (receivedData: any) => {
        console.log("AUTO NOTES STT ERROR - ", receivedData);
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("message", "STT Error during auto notes");
          Sentry.captureMessage(JSON.stringify(receivedData));
        });
      },
    });
  }, []);

  useEffect(() => {
    function forceSTTRestartIfBufferIsFull() {
      if (localSTTData && !localSTTData.isFinal) {
        if (localSTTData.text.split(" ").length > forceRestartWordCount) {
          sendRestartSTTRequestToParentWindow();
          // restarts slt to make it break at the desired word count
        }
      }
    }
    forceSTTRestartIfBufferIsFull();
  }, [localSTTData, forceRestartWordCount]);

  useEffect(() => {
    // console.log("localSTTData", localSTTData);
    function appendLocalSTTDataToActiveBufferBlockOnIsFinal() {
      if (localSTTData && localSTTData.isFinal) {
        //TODO: the active block will be built upto like 100 words...
        if (!localSTTData.text) return;
        dispatch(setActiveTranscriptBufferBlock({ text: localSTTData.text }));
      }
    }
    appendLocalSTTDataToActiveBufferBlockOnIsFinal();
  }, [localSTTData, dispatch]);

  useEffect(() => {
    function flushSTTBufferIfFull() {
      if (activeTranscriptBufferBlock.wordCount >= autoNotesSTTBufferWordCount || !isAutoNotesToggledOn) {
        // a change in previousTranscriptBufferBlocks will trigger the insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks to run and generate auto notes results.
        dispatch(setPreviousTranscriptBufferBlocks(activeTranscriptBufferBlock));
        dispatch(resetActiveTranscriptBufferBlock());
        setLocalSTTData(null);
      }
    }
    flushSTTBufferIfFull();
  }, [activeTranscriptBufferBlock, autoNotesSTTBufferWordCount, isAutoNotesToggledOn, dispatch, setLocalSTTData]);

  useEffect(() => {
    function insertAutoNotesLoaderBlockFromPreviousTranscriptBufferBlocks() {
      const latestBlock = previousTranscriptBufferBlocks[previousTranscriptBufferBlocks.length - 1];
      if (!latestBlock) return;
      const isLatestBlockAlreadyInserted = insertedTranscriptBufferBlocks.some((block) => block.blockId === latestBlock.blockId);
      if (!isLatestBlockAlreadyInserted) {
        insertAutoNotesLoaderBlock({ finalizedText: latestBlock.finalizedText, blockId: latestBlock.blockId });
        // NOTE: the latest block is now saved in the insertedTranscriptBufferBlocks state so that we can keep track of which blocks have been inserted into the editor.
        dispatch(setInsertedTranscriptBufferBlocks({ blockId: latestBlock.blockId }));
        dispatch(resetActiveTranscriptBufferBlock());
      }
    }
    insertAutoNotesLoaderBlockFromPreviousTranscriptBufferBlocks();
  }, [previousTranscriptBufferBlocks, insertedTranscriptBufferBlocks, dispatch]);

  useEffect(() => {
    //NOTE: this might not happen when in iframe , need to handle it somehow.
    function savePendingSTTDataWhenVideoStops() {
      if (!isVideoPlaying && localSTTData) {
        dispatch(setEndAutoNotesSTT());
        dispatch(setActiveTranscriptBufferBlock({ text: localSTTData.text }));
        setLocalSTTData(null);
        //NOTE: if we add this to previousTranscriptBufferBlocks, it will trigger the insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks to run. That will generate auto notes from this block in the editor.
      }
    }
    savePendingSTTDataWhenVideoStops();
  }, [isVideoPlaying, localSTTData, dispatch, setLocalSTTData]);

  useEffect(() => {
    function startOrEndAutoNotes() {
      const shouldStartAutoNotesSTT = autoNotesVideoLang && autoNotesResultLang && isExtensionMediaPermitted && isAutoNotesToggledOn && (currentVideo?.videoType === "iframe" || isVideoPlaying);
      if (shouldStartAutoNotesSTT) {
        if (localSTTData?.text.length) {
          // NOTE: this covers the case when we are starting auto notes but find some localSTT data from the previous execution that was kept around.
          dispatch(setActiveTranscriptBufferBlock({ text: localSTTData.text }));
          setLocalSTTData(null);
        }
        dispatch(setStartAutoNotes());
        const isAutoNotesPermissionRequested = localStorage.getItem("isAutoNotesPermissionRequested");
        if (isAutoNotesPermissionRequested) {
          trackEvent({
            eventType: eventTypes.success.AUTO_NOTES_START,
          });
          localStorage.removeItem("isAutoNotesPermissionRequested");
        }
      } else {
        //end the auto notes stt as conditions are not met
        dispatch(setEndAutoNotesSTT());
      }
    }
    startOrEndAutoNotes();
    //don't include localSTTData.text in the dependency array.
  }, [autoNotesVideoLang, autoNotesResultLang, isVideoPlaying, isExtensionMediaPermitted, isAutoNotesToggledOn, currentVideo, dispatch, setLocalSTTData]);

  return (
    <Background width={width} className="ce-block" isVisible={isAutoNotesVisible || isAutoNotesTemporaryVisible}>
      <AutoNoteContainer>
        <TitleWrapper>
          <Typography16 text={t("AutoNotes")} color="--gray17" weight={700} />
          {env.currentEnv === "development" && <Typography12 text={`Current buffer word count: ${activeTranscriptBufferBlock.wordCount}`} color="--gray17" weight={700} />}
          {env.currentEnv === "development" && <Typography12 text={`STT buffer word count: ${localSTTData?.text.split(" ").length || 0}`} color="--gray17" weight={700} />}
        </TitleWrapper>
        <MainContentWrapper>
          <AutoNotesTextContent>
            <ConfirmedSTTResult>{activeTranscriptBufferBlock.finalizedText.trim() + " "}</ConfirmedSTTResult>
            {localSTTData?.text && <PendingSTTResult>{localSTTData.text.trim()}</PendingSTTResult>}
          </AutoNotesTextContent>
        </MainContentWrapper>
      </AutoNoteContainer>
    </Background>
  );
};

export default AutoNotesMainUI;

const Background = styled.div<{ width: number; isVisible: boolean }>`
  position: absolute;
  bottom: 96px;
  //   max-width: 470px;
  width: 80%;
  max-width: 696px;
  left: 50%;
  transform: translateX(-48%);

  border: 1px solid var(--blue7);
  border-radius: 12px;
  z-index: 990;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  &:hover {
    opacity: 1;
    display: block;
  }
  background-color: white;
`;

const AutoNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px;
  gap: 8px;
`;

const ConfirmedSTTResult = styled.span`
  color: var(--gray17);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  display: inline;
`;

const PendingSTTResult = styled.span`
  color: var(--gray11);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  display: inline;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 24rem;
`;

const AutoNotesTextContent = styled.div`
  direction: rtl;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

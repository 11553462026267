import { LocalSTTData } from "components/smartautonotes/types";
import { create } from "zustand";

interface AutoNotesTranscriptState {
  localSTTData: LocalSTTData | null;
  setLocalSTTData: (localSTTData: LocalSTTData | null) => void;
}

const useAutoNotesTranscriptStore = create<AutoNotesTranscriptState>((set) => ({
  localSTTData: null,
  setLocalSTTData: (localSTTData) => {
    set({ localSTTData });
  },
}));

export default useAutoNotesTranscriptStore;
